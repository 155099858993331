<template>
  <v-container fill-height fluid class="align-center justify-center">
    <div v-if="api.isLoading || apiSSO.isLoading">
      <v-progress-circular
        indeterminate
        color="white"
        :size="150"
      ></v-progress-circular>
      <div class="white--text text-center pa-3">{{ stage }}</div>
    </div>
    <div v-else-if="api.isError" class="text-center">
      <AError class="text-left" :api="api" />
      <v-btn outlined color="white" @click="api.fetch()">Try Again</v-btn>
    </div>
    <div v-else-if="apiSSO.isError" class="text-center">
      <AError class="text-left" :api="apiSSO" />
      <v-btn outlined color="white" @click="apiSSO.fetch()">Try Again</v-btn>
    </div>
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    ...mapState({
      //
    }),
    stage() {
      var text = "Loading"
      if(this.api.isLoading) text = "Validating token"
      if(this.apiSSO.isLoading) text = "Logging in"
      return text
    },
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[apiSSO]
    apiSSO: new Api(),
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$env.VUE_APP_URL_API_MASTERCLASS}/vision/expressLogin`
    );
    this.api.setParams({
      token: this.$route.query.token,
    });
    this.api.setCallbackCompleted((resp) => {
      this.apiSSO.setParams({
        email: resp.email,
        name: `${resp.fname} ${resp.lname}`,
        roles: resp.roles,
      });
      this.apiSSO.fetch();
    });
    //EOC
    //BOC:[apiSSO]
    this.apiSSO.setMethod(`POST`);
    this.apiSSO.setUrl(
      `${this.$service.sso}/v1/en/vision/expressLogin`
    );
    this.apiSSO.setCallbackCompleted((resp) => {
      this.$store.commit("updateAuth", resp);
      this.$router.push({ name: 'HrdcApplication'});
      this.$store.dispatch("showMessage", "Welcome to YYC CRM!");
    });
    //EOC
    this.api.fetch();
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>